<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { TripStatus } from "~/api/data-contracts";
import { RiftCheckbox } from "~/shared";

const tripStatuses = Object.values(TripStatus).filter(status => typeof status === 'string') as TripStatus[];
const selectedStatuses = ref<TripStatus[]>([]);
const router = useRouter();

const toggleStatus = (status: TripStatus) => {
  const index = selectedStatuses.value.indexOf(status);
  if (index !== -1) {
    selectedStatuses.value.splice(index, 1);
  } else {
    selectedStatuses.value.push(status);
  }
  updateRoute();
};

const updateRoute = () => {
  router.push({ query: { status: selectedStatuses.value.length ? selectedStatuses.value : undefined } });
};

const initializeStatus = () => {
  const queryStatuses = Array.isArray(router.currentRoute.value.query.status)
      ? router.currentRoute.value.query.status
      : router.currentRoute.value.query.status
          ? [router.currentRoute.value.query.status]
          : [];

  selectedStatuses.value = queryStatuses.filter((status) => tripStatuses.includes(status as TripStatus)) as TripStatus[];
};

onMounted(() => {
  initializeStatus();
});

</script>

<template>
  <div :class="$style['widget']">
    <div
      v-for="(status, index) in tripStatuses"
      :id="`checkbox-${status}_${index}`"
      :key="`checkbox-${status}_${index}`"
      :class="$style['checkbox-container']"
    >
      <client-only>
        <RiftCheckbox
          :label="status"
          :model-value="selectedStatuses.includes(status)"
          @change="() => toggleStatus(status)"
        >
          <template #label>
            <span :class="$style['widget-status-label']">{{ status }}</span>
          </template>
        </RiftCheckbox>
      </client-only>
    </div>
  </div>
</template>


<style lang="postcss" module>
.widget {
  width: 200px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: #fff;
}

.checkbox-container {
  margin-bottom: 10px;
}



.widget-status-label {
  text-transform: lowercase;
  margin-top: 16px;
  font-size: 14px;
}
</style>
